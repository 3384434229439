import React from "react";

// markup
const IndexPage = () => {
  return (
    <div>
      <h2>Datenschutzerklärung</h2>

      <h3>Grundlegendes</h3>

      <p>
        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art,
        den Umfang und den Zweck der Erhebung und Verwendung personenbezogener
        Daten durch den Websitebetreiber {/* [Ihre Kontaktdaten einfügen]  */}
        informieren.
      </p>

      <p>
        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt I
        hre personenbezogenen Daten vertraulich und entsprechend der
        gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
        Weiterentwicklung dieser Webseite Änderungen an dieser
        Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich
        die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
      </p>

      <p>
        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten”
        oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
      </p>

      <h3>Zugriffsdaten</h3>

      <p>
        Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres
        berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über
        Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf
        dem Server der Website ab. Folgende Daten werden so protokolliert:
        <ul>
          <li>Besuchte Website</li>
          <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
          <li>Menge der gesendeten Daten in Byte</li>
          <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
          <li>Verwendeter Browser</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Verwendete IP-Adresse</li>
        </ul>
        Die Server-Logfiles werden für maximal 7 Tage gespeichert und
        anschließend gelöscht. Die Speicherung der Daten erfolgt aus
        Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können.
        Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von
        der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
      </p>

      <h3>Reichweitenmessung & Cookies</h3>

      <p>
        Diese Website verwendet Cookies zur pseudonymisierten
        Reichweitenmessung, die entweder von unserem Server oder dem Server
        Dritter an den Browser des Nutzers übertragen werden. Bei Cookies
        handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert
        werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von
        Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser
        Website. Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung
        auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser
        Dateien hier widersprechen:
        <ul className="list-disc">
          <li>
            Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
            http://optout.networkadvertising.org/?c=1#!/
          </li>
          <li>
            Cookie-Deaktivierungsseite der US-amerikanischen Website:
            http://optout.aboutads.info/?c=2#!/
          </li>
          <li>
            Cookie-Deaktivierungsseite der europäischen Website:
            http://optout.networkadvertising.org/?c=1#!/
          </li>
        </ul>
        Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen.
        Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser
        Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende
        Einstellungen vornehmen.
      </p>

      <h3>Erfassung und Verarbeitung personenbezogener Daten</h3>

      <p>
        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten
        nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie
        in die Datenerhebung einwilligen. Als personenbezogene Daten gelten
        sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen
        und welche zu Ihnen zurückverfolgt werden können – also beispielsweise
        Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.
      </p>
      <p>
        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
        machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch
        (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
        Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der
        Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind
        Rückschlüsse auf Ihre Person nicht möglich.
      </p>
      <p>
        Wir verarbeiten personenbezogene Daten wie Vorname, Nachname,
        IP-Adresse, E-Mail-Adresse, Wohnort, Postleitzahl und Inhaltsangaben aus
        dem Kontaktformular. Wir verarbeiten personenbezogene Daten nur nach
        ausdrücklicher Erlaubnis der betreffenden Nutzer und unter Einhaltung
        der geltenden Datenschutzbestimmungen. Die Verarbeitung der
        personenbezogenen Daten erfolgt aufgrund unseres berechtigten Interesses
        zur Erfüllung unserer vertraglich vereinbarten Leistungen und zur
        Optimierung unseres Online-Angebotes.
      </p>
      <h3>Umgang mit Kontaktdaten</h3>

      <p>
        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
        Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert,
        damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
        zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten
        nicht an Dritte weitergegeben.
      </p>

      <h3>Umgang mit Kommentaren und Beiträgen</h3>

      <p>
        Hinterlassen Sie auf dieser Website einen Beitrag oder Kommentar, wird
        Ihre IP-Adresse gespeichert. Dies erfolgt aufgrund unserer berechtigten
        Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO und dient der
        Sicherheit von uns als Websitebetreiber: Denn sollte Ihr Kommentar gegen
        geltendes Recht verstoßen, können wir dafür belangt werden, weshalb wir
        ein Interesse an der Identität des Kommentar- bzw. Beitragsautors haben.
      </p>

      {/* <h3>Google Analytics</h3>

Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.

Google LLC hält das europäische Datenschutzrecht ein und ist unter dem Privacy-Shield-Abkommen zertifiziert: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active

Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IP-Adresse des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.

Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene Dienstleistungen.

Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um Reports über die Aktivität auf der Website zu erstellen, um unser Online-Angebot zu verbessern.

Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.

Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin: https://tools.google.com/dlpage/gaoptout?hl=de

Alternativ verhindern Sie mit einem Klick auf diesen Link (WICHTIG: Opt-Out-Link einfügen), dass Google Analytics innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die Speicherung von Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig, ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.

Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.: 

•	https://policies.google.com/privacy/partners?hl=de (Daten, die von Google-Partnern erhoben werden)
•	https://adssettings.google.de/authenticated (Einstellungen über Werbung, die Ihnen angezeigt wird)
•	https://policies.google.com/technologies/ads?hl=de (Verwendung von Cookies in Anzeigen)

<h3>Nutzung von Social-Media-Plugins von Facebook</h3>

Aufgrund unseres berechtigten Interesses an der Analyse, Optimierung und dem Betrieb unseres Online-Angebotes (im Sinne des Art. 6 Abs. 1 lit. f. DSGVO), verwendet diese Website das Facebook-Social-Plugin, welches von der Facebook Inc. (1 Hacker Way, Menlo Park, California 94025, USA) betrieben wird. Erkennbar sind die Einbindungen an dem Facebook-Logo bzw. an den Begriffen „Like“, „Gefällt mir“, „Teilen“ in den Farben Facebooks (Blau und Weiß). Informationen zu allen Facebook-Plugins finden Sie über den folgenden Link: https://developers.facebook.com/docs/plugins/

Facebook Inc. hält das europäische Datenschutzrecht ein und ist unter dem Privacy-Shield-Abkommen zertifiziert: https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active

Das Plugin stellt eine direkte Verbindung zwischen Ihrem Browser und den Facebook-Servern her. Der Websitebetreiber hat keinerlei Einfluss auf die Natur und den Umfang der Daten, welche das Plugin an die Server der Facebook Inc. übermittelt. Informationen dazu finden Sie hier: https://www.facebook.com/help/186325668085084

Das Plugin informiert die Facebook Inc. darüber, dass Sie als Nutzer diese Website besucht haben. Es besteht hierbei die Möglichkeit, dass Ihre IP-Adresse gespeichert wird. Sind Sie während des Besuchs auf dieser Website in Ihrem Facebook-Konto eingeloggt, werden die genannten Informationen mit diesem verknüpft.

Nutzen Sie die Funktionen des Plugins – etwa indem Sie einen Beitrag teilen oder „liken“ –, werden die entsprechenden Informationen ebenfalls an die Facebook Inc. übermittelt.
Möchten Sie verhindern, dass die Facebook. Inc. diese Daten mit Ihrem Facebook-Konto verknüpft, loggen Sie sich bitte vor dem Besuch dieser Website bei Facebook aus und löschen Sie die gespeicherten Cookies. Über Ihr Facebook-Profil können Sie weitere Einstellungen zur Datenverarbeitung für Werbezwecke tätigen oder der Nutzung Ihrer Daten für Werbezwecke widersprechen. Zu den Einstellungen gelangen Sie hier: 

•	Profileinstellungen bei Facebook: https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
•	Cookie-Deaktivierungsseite der US-amerikanischen Website: http://optout.aboutads.info/?c=2#!/
•	Cookie-Deaktivierungsseite der europäischen Website: http://optout.networkadvertising.org/?c=1#!/

Welche Daten, zu welchem Zweck und in welchem Umfang Facebook Daten erhebt, nutzt und verarbeitet und welche Rechte sowie Einstellungsmöglichkeiten Sie zum Schutz Ihrer Privatsphäre haben, können Sie in den Datenschutzrichtlinien von Facebook nachlesen. Diese finden Sie hier: https://www.facebook.com/about/privacy/ 
 */}
      <h3>Newsletter-Abonnement</h3>

      <p>
        Der Websitebetreiber bietet Ihnen einen Newsletter an, in welchem er Sie
        über aktuelle Geschehnisse und Angebote informiert. Möchten Sie den
        Newsletter abonnieren, müssen Sie eine valide E-Mail-Adresse angeben.
        Wenn Sie den Newsletter abonnieren, erklären Sie sich mit dem
        Newsletter-Empfang und den erläuterten Verfahren einverstanden.
      </p>

      {/* Der Newsletterversand erfolgt durch den Versanddienstleister XY, einer Versandplattform des Anbieters ABC, Beispielstraße 5, 12345 Beispielstadt, Deutschland. Informationen über die Datenschutzbestimmungen des Versanddienstleisters erhalten Sie unter: beispiel-XY.de/datenschutz. */}
      <p>
        Widerruf und Kündigung: Ihre Einwilligung zum Erhalt des Newsletter
        können Sie jederzeit widerrufen und somit das Newsletter-Abonnement
        kündigen. Nach Ihrer Kündigung erfolgt die Löschung Ihre
        personenbezogenen Daten. Ihre Einwilligung in den Newsletterversand
        erlischt gleichzeitig. Am Ende jedes Newsletters finden Sie den Link zur
        Kündigung.
      </p>

      {/* <p>
Hinweis: Zusätzlich sollten Sie in diesem Teil der Datenschutzerklärung noch Informationen über die Inhalte Ihres Newsletters geben, z. B. ob im Newsletter auch Werbung versandt wird. */}

      {/* Des Weiteren sollten Sie angeben, wie die Daten beim Versanddienstleister gespeichert werden (pseudonymisiert oder nicht), für welche Zwecke die Daten erhoben werden, ob Daten an Dritte weitergegeben werden, ob und wie statistische Erhebungen oder Analysen durchgeführt werden etc. */}

      {/* Informieren Sie sich am besten direkt bei Ihrem Versanddienstleister. Ggf. müssen Sie mit ihm auch einen Vertrag zur Auftragsdatenverarbeitung abschließen. */}

      <h3>Rechte des Nutzers</h3>

      <p>
        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft
        darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert
        wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und
        auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen
        Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität
        geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig
        verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einreichen.
      </p>
      <h3>Löschung von Daten</h3>

      <p>
        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung
        von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein
        Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden,
        sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es
        keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine
        Löschung nicht durchgeführt werden kann, da die Daten für zulässige
        gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der
        Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht
        für andere Zwecke verarbeitet.
      </p>

      <h3>Widerspruchsrecht</h3>

      <p>
        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
        machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit
        widersprechen.
      </p>
      <p>
        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu
        Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen
        bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen
        Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie
        sich bitte an folgende E-Mail-Adresse: [E-Mail-Adresse einfügen]
      </p>

      {/* Hinweis: Je nachdem welche personenbezogenen Daten, Cookies, Plugins etc. Sie auf Ihrer Webseite verwenden, müssen Sie in Ihrer Datenschutzerklärung weitere Informationen aufführen. 

Dazu gehören beispielsweise Informationen über (Liste ist nicht vollständig):

•	Die Weitergabe von Daten an Dritte und Drittanbieter
•	Einbindungen von Diensten und Inhalten Dritter (z. B. Google-Fonts oder YouTube-Videos)
•	Erbringung vertraglicher Leistungen
•	Kontaktaufnahme über das Kontaktformular
•	Verwendung von Session-Cookies
•	Einsatz von datenverarbeitenden Anti-Spam-Plugins
•	Nutzung des Google Remarketing-Tags
•	Verwendung von Google+ oder Twitter-Schaltflächen */}
    </div>
  );
};

export default IndexPage;
